// above are  polyfill
import React from 'react';
import { Home } from "src/assembled"
import { SEO } from "src/components/utils"
import { Footer } from "src/components"


export default function Root({ location }) {
  // seo
  const seo = <SEO
  title="디어젠"
  description="디어젠은 AI와 집단지성의 통합을 통해 희귀, 난치성 질병의 치료를 앞당깁니다."
  pathname={location.pathname} />


  // render
    const render = <main role="main">
    <Home location={location} />
  </main>


  return <>
    {seo}
    {render}
    <Footer location={location} />
  </>
}
